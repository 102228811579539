import React, { useState, useEffect } from 'react';
import { Loader2, Upload } from 'lucide-react';
import { functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import Modal from './Modal';

const CreateTableModal = ({ 
  isOpen, 
  onClose, 
  onSubmit, 
  isLoading 
}) => {
  const [activeTab, setActiveTab] = useState('existing');
  const [formData, setFormData] = useState({
    name: '',
    id: '',
    prompt: '',
    dataset: '',
    datasetInput: '',
    file: null
  });
  const [fileError, setFileError] = useState('');
  const [error, setError] = useState('');
  const [datasets, setDatasets] = useState([]);
  const [isLoadingDatasets, setIsLoadingDatasets] = useState(false);

  useEffect(() => {
    if (isOpen) {
      fetchDatasets();
    }
  }, [isOpen]);

  const fetchDatasets = async () => {
    try {
      setIsLoadingDatasets(true);
      const getDatasets = httpsCallable(functions, 'getDatasets');
      const result = await getDatasets();
      
      // Note: result.data already contains the unwrapped response
      if (result.data.success && Array.isArray(result.data.datasets)) {
        setDatasets(result.data.datasets);
      } else {
        throw new Error('Failed to fetch datasets');
      }
    } catch (err) {
      console.error('Error fetching datasets:', err);
      setError('Failed to load datasets. Please try again.');
    } finally {
      setIsLoadingDatasets(false);
    }
  };

  const validateTableName = (name) => {
    const validPattern = /^[a-zA-Z0-9_.]+$/;
    return validPattern.test(name);
  };

  const getTableNameError = (name) => {
    if (!name) return '';
    if (name.includes(' ')) {
      return 'Table name cannot contain spaces';
    }
    if (!validateTableName(name)) {
      return 'Table name can only contain letters, numbers, dots (.) and underscores (_)';
    }
    return '';
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type !== 'text/csv') {
        setFileError('Please upload a CSV file');
        return;
      }
      if (file.size > 50 * 1024 * 1024) {
        setFileError('File size should not exceed 50MB');
        return;
      }
      setFileError('');
      setFormData(prev => ({ ...prev, file }));
    }
  };

  const handleSubmit = async () => {
    setError('');
    try {
      const tableNameError = getTableNameError(formData.name);
      if (tableNameError) {
        setError(tableNameError);
        return;
      }
  
      if (activeTab === 'existing') {
        await onSubmit({
          type: 'existing',
          ...formData
        });
      } else {
        if (!formData.file || !formData.name || (!formData.dataset && !formData.datasetInput)) {
          setError('Please fill in all required fields');
          return;
        }
        await onSubmit({ 
          type: 'new',
          name: formData.name,
          dataset: formData.datasetInput || formData.dataset,
          file: formData.file,
          prompt: formData.prompt
        });
      }
    } catch (err) {
      const errorMessage = err.message || 'Failed to create table';
      console.error('Error in CreateTableModal:', errorMessage);
      setError(errorMessage);
      throw err;
    }
  };

  const isSubmitDisabled = () => {
    if (activeTab === 'existing') {
      return !formData.name || !formData.id || !formData.prompt || getTableNameError(formData.name);
    } else {
      const hasDataset = formData.dataset || formData.datasetInput;
      return !formData.name || !hasDataset || (!formData.file && !fileError) || !formData.prompt || getTableNameError(formData.name);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setFormData({ name: '', id: '', prompt: '', dataset: '', datasetInput: '', file: null });
        setFileError('');
        setActiveTab('existing');
        setError('');
      }}
      title="Add Table"
      onSubmit={handleSubmit}
      isLoading={isLoading}
      confirmText="Add Table"
      confirmDisabled={isSubmitDisabled()}
    >
      <div className="space-y-4 max-h-[calc(100vh-300px)] overflow-y-auto pb-4">
        {/* Tab Navigation */}
        <div className="flex space-x-4 mb-4 border-b border-gray-200">
          <button
            className={`px-4 py-2 font-medium ${
              activeTab === 'existing'
                ? 'text-primary border-b-2 border-primary'
                : 'text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setActiveTab('existing')}
          >
            Existing Table
          </button>
          <button
            className={`px-4 py-2 font-medium ${
              activeTab === 'new'
                ? 'text-primary border-b-2 border-primary'
                : 'text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setActiveTab('new')}
          >
            Create New Table
          </button>
        </div>
  
        {/* Common Fields */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Table Name * <span className="text-xs text-gray-500">(letters, numbers, dots, underscores only)</span>
          </label>
          <input
            type="text"
            value={formData.name}
            onChange={(e) => {
              const newName = e.target.value;
              setFormData(prev => ({ ...prev, name: newName }));
              const error = getTableNameError(newName);
              setError(error);
            }}
            className={`w-full p-2 border ${!formData.name || !getTableNameError(formData.name) ? 'border-gray-300' : 'border-red-500'} rounded`}
            placeholder="Enter table name"
          />
          {formData.name && getTableNameError(formData.name) && (
            <p className="mt-1 text-sm text-red-600">
              {getTableNameError(formData.name)}
            </p>
          )}
        </div>
  
        {activeTab === 'existing' ? (
          <>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Table ID *
              </label>
              <input
                type="text"
                value={formData.id}
                onChange={(e) => setFormData(prev => ({ ...prev, id: e.target.value }))}
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="project.dataset.table"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Table Prompt *
              </label>
              <textarea
                value={formData.prompt}
                onChange={(e) => setFormData(prev => ({ ...prev, prompt: e.target.value }))}
                className="w-full p-2 border border-gray-300 rounded"
                rows={4}
                placeholder="Enter table prompt"
              />
            </div>
          </>
        ) : (
          <>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Dataset *
              </label>
              {isLoadingDatasets ? (
                <div className="flex items-center justify-center p-4">
                  <Loader2 className="h-6 w-6 animate-spin text-gray-500" />
                </div>
              ) : (
                <div>
                  <div className="flex gap-4 mb-2">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="datasetType"
                        checked={!formData.datasetInput}
                        onChange={() => setFormData(prev => ({ 
                          ...prev, 
                          datasetInput: '',
                          dataset: prev.dataset || '' 
                        }))}
                        className="mr-2"
                      />
                      Select Existing
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="datasetType"
                        checked={!!formData.datasetInput}
                        onChange={() => setFormData(prev => ({ 
                          ...prev,
                          dataset: '',
                          datasetInput: prev.datasetInput || ' '
                        }))}
                        className="mr-2"
                      />
                      Create New
                    </label>
                  </div>
  
                  {!formData.datasetInput ? (
                    <select
                      value={formData.dataset}
                      onChange={(e) => setFormData(prev => ({ ...prev, dataset: e.target.value }))}
                      className="w-full p-2 border border-gray-300 rounded"
                      disabled={isLoadingDatasets}
                    >
                      <option value="">Select a dataset</option>
                      {datasets.map((dataset) => (
                        <option key={dataset.id} value={dataset.id}>
                          {dataset.id} ({dataset.location})
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type="text"
                      value={formData.datasetInput}
                      onChange={(e) => setFormData(prev => ({ ...prev, datasetInput: e.target.value }))}
                      className="w-full p-2 border border-gray-300 rounded"
                      placeholder="Enter new dataset name"
                    />
                  )}
                </div>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Table Prompt *
              </label>
              <textarea
                value={formData.prompt}
                onChange={(e) => setFormData(prev => ({ ...prev, prompt: e.target.value }))}
                className="w-full p-2 border border-gray-300 rounded"
                rows={3}
                placeholder="Enter table prompt"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Upload CSV File *
              </label>
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  <Upload className="mx-auto h-12 w-12 text-gray-400" />
                  <div className="flex text-sm text-gray-600">
                    <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-primary hover:text-primary focus-within:outline-none">
                      <span>Upload a file</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                        accept=".csv"
                        onChange={handleFileChange}
                      />
                    </label>
                  </div>
                  <p className="text-xs text-gray-500">CSV up to 50MB</p>
                </div>
              </div>
              {formData.file && (
                <p className="mt-2 text-sm text-gray-600">
                  Selected file: {formData.file.name}
                </p>
              )}
              {fileError && (
                <p className="mt-2 text-sm text-red-600">
                  {fileError}
                </p>
              )}
            </div>
          </>
        )}
        {error && !getTableNameError(formData.name) && (
          <p className="mt-2 text-sm text-red-600">
            {error}
          </p>
        )}
      </div>
    </Modal>
  );
};

export default CreateTableModal;