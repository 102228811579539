import React, { useState, useEffect } from 'react';
import Card from './Card';
import TextEditor from './TextEditor';
import { db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { Loader2 } from 'lucide-react';
import { convertMarkdownToHtml, convertHtmlToMarkdown } from './markdownConverter';

const PromptManagement = () => {
  const [configData, setConfigData] = useState({
    prompt: '',
    schema: '',
    explanationPrompt: '',
    promptId: '',
    schemaId: '',
    explanationPromptId: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [saveStatus, setSaveStatus] = useState({ type: '', message: '' });

  const formatText = (text) => {
    if (!text) return '';
    const lines = text.split('\n');
    return lines
      .map(line => {
        const trimmedLine = line.trim();
        if (!trimmedLine) return null;
        if (trimmedLine.startsWith('•') || trimmedLine.startsWith('-')) {
          return trimmedLine;
        }
        return trimmedLine;
      })
      .filter(Boolean)
      .join('\n');
  };

  useEffect(() => {
    const fetchConfigData = async () => {
      try {
        setIsLoading(true);
        const configDoc = await getDoc(doc(db, 'DBI Configuration', 'Config'));
        
        if (configDoc.exists()) {
          const promptId = configDoc.data().Prompt;
          const schemaId = configDoc.data().Schema;
          const explanationPromptId = configDoc.data().Explanation_Prompt;
          
          const promptDoc = await getDoc(doc(db, 'DBI Configuration', 'Prompts'));
          const schemaDoc = await getDoc(doc(db, 'DBI Configuration', 'Schema'));
          const explanationPromptDoc = await getDoc(doc(db, 'DBI Configuration', 'Explanation Prompts'));

          const promptContent = promptDoc.exists() ? promptDoc.data()[promptId] : '';
          const schemaContent = schemaDoc.exists() ? schemaDoc.data()[schemaId] : '';
          const explanationPromptContent = explanationPromptDoc.exists() ? explanationPromptDoc.data()[explanationPromptId] : '';

          setConfigData({
            prompt: convertMarkdownToHtml(promptContent),
            schema: convertMarkdownToHtml(schemaContent),
            explanationPrompt: convertMarkdownToHtml(explanationPromptContent),
            promptId,
            schemaId,
            explanationPromptId
          });
        } else {
          setError('Configuration data not found');
        }
      } catch (err) {
        setError(err.message);
        console.error('Error fetching config data:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchConfigData();
  }, []);

  const handleSave = async (type, content) => {
    try {
      setSaveStatus({ type: 'loading', message: 'Saving...' });
      let docRef;
      let id;
      
      switch(type) {
        case 'prompt':
          docRef = doc(db, 'DBI Configuration', 'Prompts');
          id = configData.promptId;
          break;
        case 'schema':
          docRef = doc(db, 'DBI Configuration', 'Schema');
          id = configData.schemaId;
          break;
        case 'explanationPrompt':
          docRef = doc(db, 'DBI Configuration', 'Explanation Prompts');
          id = configData.explanationPromptId;
          break;
        default:
          throw new Error('Invalid type');
      }
      
      // Convert HTML to Markdown before saving to Firestore
      const markdownContent = convertHtmlToMarkdown(content);
      
      await updateDoc(docRef, {
        [id]: markdownContent
      });

      setConfigData(prev => ({
        ...prev,
        [type]: content // Keep HTML version in state for display
      }));
      
      setSaveStatus({ type: 'success', message: 'Saved successfully!' });
      setTimeout(() => setSaveStatus({ type: '', message: '' }), 3000);
    } catch (err) {
      console.error('Error saving:', err);
      setSaveStatus({ type: 'error', message: 'Failed to save changes' });
      setTimeout(() => setSaveStatus({ type: '', message: '' }), 3000);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-red-500">
        Error loading configuration: {error}
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="flex justify-between items-center mb-4">
        {saveStatus.message && (
          <div className={`px-4 py-2 rounded ${
            saveStatus.type === 'success' ? 'bg-green-100 text-green-700' :
            saveStatus.type === 'error' ? 'bg-red-100 text-red-700' :
            'bg-blue-100 text-blue-700'
          }`}>
            {saveStatus.message}
          </div>
        )}
      </div>
      
      <div className="grid grid-cols-1 gap-6 w-full max-w-4xl">
        <Card>
          <div className="h-[360px]">
            <TextEditor
              title="General Prompt"
              initialContent={formatText(configData.prompt)}
              onSave={(content) => handleSave('prompt', content)}
              onCancel={() => setSaveStatus({ type: '', message: '' })}
            />
          </div>
        </Card>

        <Card>
          <div className="h-[360px]">
            <TextEditor
              title="Explanation Prompt"
              initialContent={formatText(configData.explanationPrompt)}
              onSave={(content) => handleSave('explanationPrompt', content)}
              onCancel={() => setSaveStatus({ type: '', message: '' })}
            />
          </div>
        </Card>

        <Card>
          <div className="h-[360px]">
            <TextEditor
              title="Schema"
              initialContent={formatText(configData.schema)}
              onSave={(content) => handleSave('schema', content)}
              onCancel={() => setSaveStatus({ type: '', message: '' })}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default PromptManagement;