import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getFirestore, doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Card from '../components/Card';
import Button from '../components/Button';
import AutoCompleteSearch from '../components/QueryComponent/AutoCompleteSearch';

const Optimization = () => {
  const [config, setConfig] = useState({ UserInputs: [] });
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [validProductCode, setValidProductCode] = useState(false);
  const [showProductCodeSuggestions, setShowProductCodeSuggestions] = useState(false);
  const [showValidationError, setShowValidationError] = useState(false);
  const [validProductCodes, setValidProductCodes] = useState([]); 
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const db = getFirestore();
        const configDoc = await getDoc(doc(db, 'Optimization', 'Configuration'));
        if (configDoc.exists()) {
          setConfig(configDoc.data());
        } else {
          console.error('Optimization Configuration document does not exist.');
        }
      } catch (err) {
        console.error('Error fetching configuration:', err);
      }
    };
    fetchConfig();
  }, []);

  useEffect(() => {
    const fetchProductCodes = async () => {
      try {
        const db = getFirestore();
        const indexCollection = collection(db, 'index');
        const docRef = await getDocs(indexCollection);
        docRef.forEach((doc) => {
          if (doc.id === 'miloubar-0000_Pressing_Pressing_data_product_code') {
            setValidProductCodes(doc.data().values.map(item => item.value));
          }
        });
      } catch (err) {
        console.error('Error fetching product codes:', err);
      }
    };
    fetchProductCodes();
  }, []);

  const handleProductCodeSelect = (suggestion) => {
    setInputs(prev => ({
      ...prev,
      product_code: suggestion.value
    }));
    setValidProductCode(true);
    setShowValidationError(false);
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let parsedValue;
    
    const integerFields = ['press'];
    const floatFields = ['required_strength', 'total_batch_weight'];
  
    if (name === 'product_code') {
      parsedValue = value;
      setValidProductCode(validProductCodes.includes(value.trim()));
    }
  
    if (integerFields.includes(name)) {
      parsedValue = value === '' ? '' : parseInt(value, 10);
    } else if (floatFields.includes(name)) {
      parsedValue = value === '' ? '' : parseFloat(value);
    } else {
      parsedValue = value;
    }
    
    setInputs(prevInputs => ({ ...prevInputs, [name]: parsedValue }));
  };

  const debouncedValidationCheck = useCallback(() => {
    if (inputs.product_code && inputs.product_code.length > 2) {
      let timer = setTimeout(() => {
        setShowValidationError(inputs.product_code && !validProductCodes.includes(inputs.product_code.trim()));
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      setShowValidationError(false);
    }
  }, [inputs.product_code, validProductCodes]);

useEffect(() => {
  debouncedValidationCheck();
}, [inputs.product_code, debouncedValidationCheck]);  

const formatLabel = (input) => {
    return input
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
};


const isFormValid = () => {
  return config.UserInputs.every(input => {
    const value = inputs[input];
    if (input === 'product_code') {
      return value && validProductCodes.includes(value.trim());
    }
    if (['press'].includes(input)) {
      return Number.isInteger(value) && value >= 1;
    } else if (['required_strength', 'total_batch_weight'].includes(input)) {
      return typeof value === 'number' && value >= 0;
    }
    return value !== undefined && value !== null && value !== '';
  });
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setResult(null);

    try {
      const functions = getFunctions();
      const getOptimalParameters = httpsCallable(functions, 'getOptimalParameters');
      
      const dataToSend = {
        press: inputs.press,
        product_code: inputs.product_code,
        required_strength: inputs.required_strength,
        total_batch_weight: inputs.total_batch_weight,
        userId: currentUser.email, // Add this line
      };

      console.log('Sending data to backend:', dataToSend);

      const response = await getOptimalParameters(dataToSend);
      console.log('Backend Response:', response.data);
      setResult(response.data);

    } catch (err) {
      console.error('Error calling getOptimalParameters:', err);
      setError(err.message || 'An unexpected error occurred.');
    } finally {
      setIsLoading(false);
    }
  };

  // Updated formatting functions
  const formatParameterValue = (number) => {
    return number !== null && number !== undefined ? Math.round(number).toString() : '-';
  };

  const formatStrengthValue = (number) => {
    return number !== null && number !== undefined ? Number(number).toFixed(2) : '-';
  };

  const formatTime = (milliseconds) => {
    return `${(milliseconds / 1000).toFixed(2)}s`;
  };

  const getStrengthColorClass = (strength, requiredStrength) => {
    if (strength === null || strength === undefined) return 'text-gray-400';
    // Round both numbers to 2 decimal places to match display format
    const roundedStrength = Number(Number(strength).toFixed(2));
    const roundedRequired = Number(Number(requiredStrength).toFixed(2));
    return roundedStrength < roundedRequired ? 'text-red-600' : 'text-green-600';
  };

  const formatDiff = (value, requiredStrength) => {
    if (value === null || value === undefined) return '';
    const diff = value - requiredStrength;
    return diff >= 0 ? `+${diff.toFixed(2)}` : diff.toFixed(2);
  };

  const renderResultTable = () => {
    if (!result) return null;

    const parameters = [
      {
        name: 'Temperature',
        key: 'temperature',
      },
      {
        name: 'Current',
        key: 'current',
      },
      {
        name: 'Feeder Speed',
        key: 'feeder_speed',
      },
      {
        name: 'Roller Gap',
        key: 'roller_gap',
      }
    ];

    const requiredStrength = inputs.required_strength;

    return (
      <Card className="mb-8 bg-gradient-to-b from-white to-primary/5 border-t-4 border-primary p-6">
        <h2 className="text-xl font-semibold text-gray-800 mb-6">Optimization Results</h2>
        
        {result.message && (
          <div className="mb-4 p-4 bg-yellow-50 border-l-4 border-yellow-400 text-yellow-700">
            {result.message}
          </div>
        )}

        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b-2 border-gray-200">
                <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">Parameter</th>
                <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">Baseline</th>
                <th className="px-4 py-3 text-left text-sm font-semibold text-accent">New Baseline</th>
                <th className="px-4 py-3 text-left text-sm font-semibold text-primary">Model</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100">
              {parameters.map((param) => (
                <tr key={param.name} className="hover:bg-gray-50">
                  <td className="px-4 py-3 text-sm font-medium text-gray-700">{param.name}</td>
                  <td className="px-4 py-3 text-sm text-gray-600">
                    {formatParameterValue(result.baselineParameters[param.key])}
                  </td>
                  <td className="px-4 py-3 text-sm text-accent">
                    {formatParameterValue(result.optimizedMovingAverages[`opt_avg_${param.key}`])}
                    <span className="text-xs text-gray-500 ml-1">
                      ({result.movingAverageWindows.global || '-'}-batch)
                    </span>
                  </td>
                  <td className="px-4 py-3 text-sm text-primary font-medium">
                    {formatParameterValue(result.recommendedParameters[param.key])}
                  </td>
                </tr>
              ))}
              <tr className="bg-gray-50 font-medium">
                <td className="px-4 py-4 text-sm text-gray-700">Strength Prediction</td>
                <td className="px-4 py-4">
                  <div className="flex flex-col">
                    <span className={getStrengthColorClass(result.baselinePredictedStrength, requiredStrength)}>
                      {formatStrengthValue(result.baselinePredictedStrength)}
                    </span>
                    <span className={`text-xs ${getStrengthColorClass(result.baselinePredictedStrength, requiredStrength)}`}>
                      ({formatDiff(result.baselinePredictedStrength, requiredStrength)})
                    </span>
                  </div>
                </td>
                <td className="px-4 py-4">
                  <div className="flex flex-col">
                    <span className={getStrengthColorClass(result.movingAveragesPredictedStrength, requiredStrength)}>
                      {formatStrengthValue(result.movingAveragesPredictedStrength)}
                    </span>
                    <span className={`text-xs ${getStrengthColorClass(result.movingAveragesPredictedStrength, requiredStrength)}`}>
                      ({formatDiff(result.movingAveragesPredictedStrength, requiredStrength)})
                    </span>
                  </div>
                </td>
                <td className="px-4 py-4">
                  <div className="flex flex-col">
                    <span className={getStrengthColorClass(result.predictedStrength, requiredStrength)}>
                      {formatStrengthValue(result.predictedStrength)}
                    </span>
                    <span className={`text-xs ${getStrengthColorClass(result.predictedStrength, requiredStrength)}`}>
                      ({formatDiff(result.predictedStrength, requiredStrength)})
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mt-6 p-4 bg-gray-50 rounded-lg">
          <h3 className="text-sm font-semibold text-gray-700 mb-2">Process Details</h3>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 text-sm text-gray-600">
            <div>
              <span className="font-medium">Time Taken:</span> {formatTime(result.optimizationLog.timeTaken)}
            </div>
            <div>
              <span className="font-medium">Iterations:</span> {result.optimizationLog.iterations}
            </div>
            <div>
              <span className="font-medium">Log ID:</span> {result.optimizationLogId || result.optimizationLog?.optimizationLogId}
            </div>
          </div>
        </div>
      </Card>
    );
  };

  return (
    <div className="container mx-auto px-0 py-2 sm:py-4">
      <h1 className="text-gray-500 mb-6 text-xl font-semibold">Optimization</h1>
      
      <Card className="mb-8">
        <h2 className="text-lg font-semibold mb-4 text-gray-700">Input Parameters</h2>
        <form onSubmit={handleSubmit} className="space-y-6" autoComplete="off">
          {config.UserInputs.map((input) => (
            <div key={input} className="flex flex-col space-y-3">
              <label
                className="text-sm font-medium text-gray-700"
                htmlFor={input}
              >
                {formatLabel(input)}
              </label>
              {input === 'product_code' ? (
                <div className="relative">
                  <input
                    className={`bg-white block w-full sm:text-sm border-b ${
                      showValidationError ? 'border-red-300' : 'border-gray-300'
                    } focus:border-secondary focus:ring-0 outline-none`}
                    id={input}
                    type="text"
                    placeholder="Enter Product Code"
                    name={input}
                    value={inputs[input] || ''}
                    onChange={handleInputChange}
                    required
                    autoComplete="off"
                    role="combobox"
                    aria-autocomplete="list"
                  />
                  {showValidationError && (
                    <p className="mt-1 text-xs text-red-500">
                      Please select a valid product code from the suggestions
                    </p>
                  )}
                  <AutoCompleteSearch
                    inputValue={inputs[input] || ''}
                    cursorPosition={(inputs[input] || '').length}
                    onSelect={handleProductCodeSelect}
                    onVisibilityChange={setShowProductCodeSuggestions}
                    isLoading={isLoading}
                  />
                </div>
              ) : (
                <input
                  className="bg-white block w-full sm:text-sm border-b border-gray-300 focus:border-secondary focus:ring-0 outline-none"
                  id={input}
                  type={
                    ['press'].includes(input)
                      ? 'number'
                      : (['required_strength', 'total_batch_weight'].includes(input) ? 'number' : 'text')
                  }
                  step={
                    ['required_strength', 'total_batch_weight'].includes(input)
                      ? '0.01'
                      : '1'
                  }
                  min={
                    ['press'].includes(input)
                      ? '1'
                      : (['required_strength', 'total_batch_weight'].includes(input) ? '0' : undefined)
                  }
                  placeholder={`Enter ${formatLabel(input)}`}
                  name={input}
                  value={inputs[input] || ''}
                  onChange={handleInputChange}
                  required
                />
              )}
            </div>
          ))}
          <div className="flex justify-end">
            <Button
              onClick={handleSubmit}
              disabled={!isFormValid() || isLoading}
              loading={isLoading}
              className={`px-4 py-2 ${
                !isFormValid() && inputs.product_code && !validProductCode
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
            >
              Submit
            </Button>
          </div>
        </form>
      </Card>

      {error && (
        <Card className="mb-8 bg-red-50 border-red-500">
          <div className="text-red-700" role="alert">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{error}</span>
          </div>
        </Card>
      )}

      {result && renderResultTable()}
    </div>
  );
};

export default Optimization;