import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { doc, updateDoc, deleteField } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { Plus, Trash2 } from 'lucide-react';
import { db, functions } from '../firebase';
import Modal from './Modal';
import CreateTableModal from './CreateTableModal';

// Separate DeleteButton component
const DeleteButton = ({ table, onDelete }) => (
  <button
    onClick={() => onDelete(table)}
    className="p-2 text-gray-500 hover:text-red-600 focus:outline-none"
    title="Delete table"
  >
    <Trash2 className="h-5 w-5" />
  </button>
);

const TableActions = forwardRef(({ tables, setTables, setSaveStatus }, ref) => {
  // At the start of the component add:
  useImperativeHandle(ref, () => ({
    handleDeleteClick
  }));

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableToDelete, setTableToDelete] = useState(null);
  const [deleteConfirmText, setDeleteConfirmText] = useState('');

  const handleCreateTable = async (formData) => {
    try {
      setIsLoading(true);
     
      if (!formData.name) {
        throw new Error('Table name is required');
      }
     
      // Check if table name already exists
      const existingTable = tables.find(t => t.name === formData.name);
      if (existingTable) {
        throw new Error('A table with this name already exists');
      }
     
      const configDoc = doc(db, 'appConfiguration', 'tables');
      
      if (formData.type === 'new') {
        if (!formData.dataset) {
          throw new Error('Dataset name is required');
        }
        if (!formData.file) {
          throw new Error('No file provided');
        }
     
        // Read file as base64
        const fileContent = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            try {
              const base64Content = e.target.result.split(',')[1];
              if (!base64Content) {
                reject(new Error('Failed to read file content'));
                return;
              }
              resolve(base64Content);
            } catch (err) {
              reject(new Error('Failed to process file content: ' + err.message));
            }
          };
          reader.onerror = () => reject(new Error('Failed to read file'));
          reader.readAsDataURL(formData.file);
        });
     
        console.log('File content read successfully, calling createTable function');
     
        // Call the cloud function to create table from file
        const createTableFunc = httpsCallable(functions, 'createTable');
        
        try {
          const result = await createTableFunc({
            fileContent,
            name: formData.name,
            dataset: formData.dataset,
            prompt: formData.prompt
          });
  
          console.log('Cloud function response:', result); // Debug log
  
          // The result from httpsCallable automatically unwraps the data field
          if (!result?.data) {
            console.error('Invalid cloud function response:', result);
            throw new Error('Invalid response from server');
          }
  
          const { success, tableId, schema, dataSample } = result.data;
  
          if (!success || !tableId) {
            console.error('Invalid response data:', result.data);
            throw new Error('Failed to create table: ' + (result.data.error || 'Unknown error'));
          }
  
          const configDoc = doc(db, 'appConfiguration', 'tables');
          await updateDoc(configDoc, {
            [formData.name]: {
              id: tableId,
              prompt: formData.prompt,
              schema: schema || [],
              schemaHash: '',
              lastSchemaCheck: null
            }
          });

          // Update local state
          setTables(currentTables => [...currentTables, {
            name: formData.name,
            id: tableId,
            prompt: formData.prompt,
            schema: schema || [],
            schemaHash: '',
            lastSchemaCheck: null,
            dataSample: dataSample || []
          }]);
          
          setIsCreateModalOpen(false);
          setSaveStatus({
            tableId: tableId,
            type: 'success',
            message: 'Table created successfully!'
          });
          setTimeout(() => setSaveStatus({ tableId: null, type: '', message: '' }), 3000);
  
        } catch (functionError) {
          console.error('Cloud function error:', functionError);
          const errorMessage = functionError.details?.message || 
                             functionError.message || 
                             'Failed to create table via cloud function';
          throw new Error(errorMessage);
        }
     
      } else {
        // Existing table creation logic
        if (!formData.id) {
          throw new Error('Table ID is required');
        }
     
        const updatedData = {
          [formData.name]: {
            id: formData.id,
            prompt: formData.prompt || '',
            schema: [],
            schemaHash: '',
            lastSchemaCheck: null
          }
        };
     
        await updateDoc(configDoc, updatedData);
     
        // Update local state
        setTables(currentTables => [...currentTables, {
          name: formData.name,
          id: formData.id,
          prompt: formData.prompt,
          schema: [],
          schemaHash: '',
          lastSchemaCheck: null
        }]);
  
        setIsCreateModalOpen(false);
        setSaveStatus({
          tableId: formData.id,
          type: 'success',
          message: 'Table created successfully!'
        });
        setTimeout(() => setSaveStatus({ tableId: null, type: '', message: '' }), 3000);
      }
     
    } catch (err) {
      console.error('Error creating table:', err);
      setSaveStatus({
        tableId: 'new',
        type: 'error',
        message: err.message || 'Failed to create table'
      });
      throw err; // Re-throw to let modal handle it
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = (table) => {
    setTableToDelete(table);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteTable = async () => {
    try {
      if (deleteConfirmText !== 'delete' || !tableToDelete) return;
      
      setIsLoading(true);
      const configDoc = doc(db, 'appConfiguration', 'tables');
      
      const updateData = {
        [tableToDelete.name]: deleteField()
      };
      
      await updateDoc(configDoc, updateData);
      
      // Update local state to remove the table
      setTables(currentTables => currentTables.filter(t => t.name !== tableToDelete.name));
      
      // Reset states and close modal
      setTableToDelete(null);
      setDeleteConfirmText('');
      setIsDeleteModalOpen(false);
      
      setSaveStatus({
        tableId: 'delete',
        type: 'success',
        message: 'Table deleted successfully!'
      });
      setTimeout(() => setSaveStatus({ tableId: null, type: '', message: '' }), 3000);
      
    } catch (err) {
      console.error('Error deleting table:', err);
      setSaveStatus({
        tableId: 'delete',
        type: 'error',
        message: err.message || 'Failed to delete table'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Table Management</h2>
        <button
          onClick={() => setIsCreateModalOpen(true)}
          className="flex items-center px-4 py-2 text-sm text-gray-700 rounded-md hover:text-secondary transition-colors"
        >
          <Plus className="h-5 w-5 mr-2" />
          Add Table
        </button>
      </div>

      {/* Create Table Modal */}
      <CreateTableModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onSubmit={handleCreateTable}
        isLoading={isLoading}
      />

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setTableToDelete(null);
          setDeleteConfirmText('');
        }}
        title={`Delete Table: ${tableToDelete?.name}`}
        onSubmit={handleDeleteTable}
        isLoading={isLoading}
        confirmText="Delete Table"
        confirmDisabled={deleteConfirmText !== 'delete'}
      >
        <div className="space-y-4">
          <p className="text-sm text-gray-600">
            This action cannot be undone. Please type <span className="font-mono font-bold">delete</span> to confirm.
          </p>
          <input
            type="text"
            value={deleteConfirmText}
            onChange={(e) => setDeleteConfirmText(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Type 'delete' to confirm"
          />
        </div>
      </Modal>
    </div>
  );
});

export default TableActions;