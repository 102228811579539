import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import DynamicQuery from './pages/DynamicQuery';
import StaticDashboard from './pages/StaticDashboard';
import Login from './pages/SignIn';
import Signup from './pages/Signup';
import PasswordReset from './pages/PasswordReset';
import Plan from './pages/Plan';
import Optimization from './pages/Optimization';
import Ask from './pages/Ask';
import Chat from './pages/Chat';
import News from './pages/News';
import AdminDashboard from './pages/AdminDashboard';
import UserPreferencesModal from './components/UserPreferencesModal';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { DashboardProvider } from './contexts/DashboardContext';
import { AskProvider } from './contexts/AskContext';
import { ConfigProvider, useConfig } from './contexts/ConfigContext';
import './assets/bubblingLoader.css';

const ALLOWED_UNREGISTERED_PATHS = ['/login', '/signup', '/reset-password'];

function LoadingSpinner() {
  return (
    <div className="fixed inset-0 bg-gray-100 flex items-center justify-center z-50">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
    </div>
  );
}

function AppContent() {
  const mainContentRef = useRef(null);
  const { currentUser, isAdmin, checkAdminStatus, isRegistrationComplete } = useAuth();
  const { config, isLoading: isConfigLoading } = useConfig();
  const location = useLocation();

// Update the useEffect in AppContent
useEffect(() => {
  let intervalId;
  let isInitialCheck = true;
  
  if (currentUser) {
    console.log('AppContent: Current user detected');
    const refreshTokenAndCheckAdmin = async () => {
      try {
        if (!isInitialCheck) {
          const adminStatus = await checkAdminStatus(currentUser);
          console.log('AppContent: Admin status after check:', adminStatus);
        }
      } catch (error) {
        console.error('Error in token refresh:', error);
      }
    };

    // Set initial check
    if (isInitialCheck) {
      isInitialCheck = false;
    }

    // Set up interval for future checks (15 minutes)
    intervalId = setInterval(refreshTokenAndCheckAdmin, 15 * 60 * 1000);
  } else {
    console.log('AppContent: No current user');
  }

  return () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  };
}, [currentUser, checkAdminStatus]);

  // Combined loading state check
  if (isConfigLoading) {
    return <LoadingSpinner />;
  }

  const shouldShowLayout = isRegistrationComplete || 
    ALLOWED_UNREGISTERED_PATHS.includes(location.pathname) ||
    !currentUser;

    return (
      <Layout mainContentRef={mainContentRef} showSidebar={shouldShowLayout}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reset-password" element={<PasswordReset />} />
          <Route path="/" element={
            <PrivateRoute>
              <Ask mainContentRef={mainContentRef} />
            </PrivateRoute>
          } />
          <Route path="/chat" element={
            <PrivateRoute>
              <Chat mainContentRef={mainContentRef} />
            </PrivateRoute>
          } />
          <Route path="/dashboard" element={
            <PrivateRoute>
              <Home mainContentRef={mainContentRef} />
            </PrivateRoute>
          } />
          <Route path="/dashboard" element={
            <PrivateRoute>
              <Home mainContentRef={mainContentRef} />
            </PrivateRoute>
          } />
          <Route path="/news" element={
            <PrivateRoute>
              <News />
            </PrivateRoute>
          } />
          <Route path="/dynamic" element={
            <PrivateRoute>
              <DynamicQuery mainContentRef={mainContentRef} />
            </PrivateRoute>
          } />
          <Route path="/static-dashboard" element={
            <PrivateRoute>
              <StaticDashboard />
            </PrivateRoute>
          } />
          <Route path="/plan" element={
            <PrivateRoute>
              <Plan />
            </PrivateRoute>
          } />
          <Route path="/optimization" element={
            <PrivateRoute>
              <Optimization />
            </PrivateRoute>
          } />
          <Route path="/admin" element={
            <PrivateRoute>
              <AdminDashboard />
            </PrivateRoute>
          } />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Layout>
    );
  }

const PrivateRoute = ({ children }) => {
  const { 
    currentUser, 
    isAdmin, 
    isRegistrationComplete, 
    registrationChecked,
    saveUserPreferences 
  } = useAuth();
  const { config, isLoading: isConfigLoading } = useConfig();
  const location = useLocation();
  const [preferencesLoading, setPreferencesLoading] = useState(false);

  const handlePreferencesSubmit = async (preferences) => {
    try {
      setPreferencesLoading(true);
      await saveUserPreferences(currentUser.email, preferences);
    } catch (error) {
      console.error('Error saving preferences:', error);
    } finally {
      setPreferencesLoading(false);
    }
  };

  // Allow immediate access to unregistered paths
  if (ALLOWED_UNREGISTERED_PATHS.includes(location.pathname)) {
    return children;
  }

  // Combined loading state check
  if (!registrationChecked || isConfigLoading || !config) {
    return <LoadingSpinner />;
  }

  // Authentication check
  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Registration check
  if (!isRegistrationComplete) {
    return (
      <div className="fixed inset-0 bg-gray-100 flex items-center justify-center z-50">
        <UserPreferencesModal
          isOpen={true}
          onClose={() => null}
          onSubmit={handlePreferencesSubmit}
          isLoading={preferencesLoading}
        />
      </div>
    );
  }

  return React.cloneElement(children, { isAdmin });
};

function App() {
  useEffect(() => {
    const currentBuildTime = localStorage.getItem('buildTime');
    const newBuildTime = process.env.REACT_APP_BUILD_TIME;

    if (currentBuildTime !== newBuildTime) {
      localStorage.setItem('buildTime', newBuildTime);
      if (currentBuildTime) {
        window.location.reload(true);
      }
    }
  }, []);

  return (
    <ThemeProvider>
      <AuthProvider>
        <ConfigProvider>
          <DashboardProvider>
            <AskProvider>
              <Router>
                <AppContent />
              </Router>
            </AskProvider>
          </DashboardProvider>
        </ConfigProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;