import React, { useState, useRef, useEffect } from 'react';
import { useTextareaHeight, LINE_HEIGHT, MAX_LINES } from '../../hooks/useTextareaHeight';
import useRecentSearches from '../../hooks/useRecentSearches';
import { SendHorizontal, Pause, Play, X, ArrowLeft, SlidersHorizontal } from 'lucide-react';
import RecentSearches from './RecentSearches';
import QuickFilter from './QuickFilter';
import MentionList from './MentionList';
import AutoCompleteSearch from './AutoCompleteSearch';

const QueryInput = ({ 
  onSubmit, 
  isLoading, 
  isFollowUp = false, 
  isFollowUpMode = false, 
  selectedText = '', 
  selectedColumns = [], 
  isCardTitle = false, 
  fetchRecentSearches,
  initialQuery = ''
}) => {
  const [inputQuery, setInputQuery] = useState(initialQuery);
  const [isFocused, setIsFocused] = useState(false);
  const [showRecentSearches, setShowRecentSearches] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [selectedHistoricalQuery, setSelectedHistoricalQuery] = useState(null);
  const textareaRef = useRef(null);
  const chipRef = useRef(null);
  const columnsChipRef = useRef(null);
  const timeoutRef = useRef(null);
  const recentSearchesRef = useRef(null);
  const isClearingRef = useRef(false);
  const [showQuickFilter, setShowQuickFilter] = useState(false);
  const [showMentionList, setShowMentionList] = useState(false);
  const [selectedTable, setSelectedTable] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [cursorPosition, setCursorPosition] = useState(0);
  const { height, adjustHeight, resetHeight, MIN_TEXTAREA_HEIGHT } = useTextareaHeight(textareaRef, inputQuery);
  const [currentPlaceholder, setCurrentPlaceholder] = useState('Use @ to focus the query');
  const [showAutoComplete, setShowAutoComplete] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (isFollowUpMode && textareaRef.current) {
      setTimeout(() => {
        textareaRef.current.focus();
      }, 100);
    }
  }, [isFollowUpMode]);

  useEffect(() => {
    if (selectedTable) {
      setCurrentPlaceholder('@ again for specific field');
    } else {
      setCurrentPlaceholder('Use @ to focus the query');
    }
  }, [selectedTable]);

  const getQueryContext = () => {
    return {
      table: selectedTable ? {
        name: selectedTable.displayName,
        id: selectedTable.id
      } : null,
      column: selectedColumn ? {
        name: selectedColumn.name,
        type: selectedColumn.type
      } : null,
      isQuickFilter: showQuickFilter
    };
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowRecentSearches(false);
    setShowAutoComplete(false);
    setSuggestions([]);
    setShowMentionList(false);
    
    if (inputQuery.trim()) {
      const context = getQueryContext();
  
      if (selectedHistoricalQuery) {
        onSubmit(inputQuery, false, '', context);
        setSelectedHistoricalQuery(null);
      } else {
        if (isFollowUpMode) {
          await onSubmit(inputQuery, { 
            text: selectedText, 
            columns: selectedColumns,
            ...context 
          });
        } else {
          await onSubmit(inputQuery, isFollowUpMode, selectedText, context);
        }
      }
    }
  };

  const {
    filteredRecentSearches,
    handleSearchFiltering,
    refreshRecentSearches,
    clearRecentSearches,
    recentSearches
  } = useRecentSearches(fetchRecentSearches, 10);

//  handleAutoComplete function
const handleAutoComplete = ({ suggestion, wordStart, wordEnd, replacementText }) => {
  const textBeforeWord = inputQuery.slice(0, wordStart);
  const textAfterWord = inputQuery.slice(wordEnd);
  
  const updatedInput = `${textBeforeWord}${replacementText}${textAfterWord}`;
  setInputQuery(updatedInput);
  setShowAutoComplete(false);
  setSuggestions([]); // Clear suggestions
  
  // Set cursor position after the inserted text
  const newPosition = wordStart + replacementText.length;
  requestAnimationFrame(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(newPosition, newPosition);
    }
    setCursorPosition(newPosition);
  });
};

  const SelectedColumnsChip = ({ columns }) => (
    <div className="flex flex-wrap gap-1 mb-2">
      {columns.map(column => (
        <span key={column} className="bg-secondary text-white text-xs px-2 py-1 rounded-full">
          {column}
        </span>
      ))}
    </div>
  );

  const handleHistoryBack = async () => {
    if (recentSearches.length === 0) {
      await refreshRecentSearches();
    }
    
    if (recentSearches.length > 0) {
      if (historyIndex === -1) {
        setHistoryIndex(0);
        setInputQuery(recentSearches[0].userQuery);
        setSelectedHistoricalQuery(recentSearches[0]);
      } else if (historyIndex < recentSearches.length - 1) {
        const newIndex = historyIndex + 1;
        setHistoryIndex(newIndex);
        setInputQuery(recentSearches[newIndex].userQuery);
        setSelectedHistoricalQuery(recentSearches[newIndex]);
      }
    }
    adjustHeight();
  };

  useEffect(() => {
    if (inputQuery !== (recentSearches[historyIndex]?.userQuery || '')) {
      setHistoryIndex(-1);
    }
  }, [inputQuery, recentSearches, historyIndex]);

  const checkForMentionTrigger = (value, cursorPos) => {
    const lastChar = value.charAt(cursorPos - 1);
    const isAtStart = cursorPos === 1;
    const prevChar = isAtStart ? ' ' : value.charAt(cursorPos - 2);
    
    if (lastChar === '@' && (!prevChar || /\s/.test(prevChar))) {
      setShowMentionList(true);
      setShowRecentSearches(false);
      setShowAutoComplete(false);
    }
  };
  
  const handleTextareaChange = async (e) => {
    const newValue = e.target.value;
    const newCursorPosition = e.target.selectionStart;
    
    setInputQuery(newValue);
    setCursorPosition(newCursorPosition);
    
    // If input is empty, reset table selection
    if (!newValue.trim()) {
      setSelectedTable(null);
      setSelectedColumn(null);
      setShowMentionList(false);
    }
    
    // Check for @ trigger
    checkForMentionTrigger(newValue, newCursorPosition);
    
    if (!showMentionList) {
      handleSearchFiltering(newValue);
    } else {
      setShowRecentSearches(false);
    }
    
    adjustHeight();
  };

  const handleKeyDown = (e) => {
    if (showMentionList) {
      // Let MentionList handle its own keyboard events
      return;
    }
  
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      
      if (showAutoComplete && suggestions.length > 0 && selectedIndex >= 0) {
        handleAutoComplete(suggestions[selectedIndex]);
        return;
      }
      
      if (highlightedIndex !== -1 && filteredRecentSearches.length > 0) {
        handleSelectRecentQuery(filteredRecentSearches[highlightedIndex]);
      } else {
        handleSubmit(e);
      }
    } else if (e.key === 'ArrowDown') {
      if (showRecentSearches && filteredRecentSearches.length > 0) {
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex < filteredRecentSearches.length - 1 ? prevIndex + 1 : prevIndex
        );
      }
    } else if (e.key === 'ArrowUp') {
      if (showRecentSearches && filteredRecentSearches.length > 0) {
        e.preventDefault();
        setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : -1));
      }
    } else if (e.key === 'Escape') {
      setShowRecentSearches(false);
      setShowMentionList(false);
      setHighlightedIndex(-1);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (recentSearchesRef.current && !recentSearchesRef.current.contains(event.target) &&
          textareaRef.current && !textareaRef.current.contains(event.target)) {
        setShowRecentSearches(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (initialQuery) {
      setInputQuery(initialQuery);
      adjustHeight();
    }
  }, [initialQuery]);

// Optimized clearInput function
const clearInput = () => {
  // Clear text and basic states
  setInputQuery('');
  setSelectedHistoricalQuery(null);
  setHighlightedIndex(-1);
  
  // Clear mention-related states
  setSelectedTable(null);
  setSelectedColumn(null);
  setShowMentionList(false);
  setCursorPosition(0);
  
  // Clear search-related states
  setShowAutoComplete(false);
  setSuggestions([]);
  setShowRecentSearches(true);
  
  // Reset UI states
  requestAnimationFrame(() => {
    resetHeight();
    textareaRef.current?.focus();
  });

  clearRecentSearches();
};

  const truncateSelectedText = (text) => {
    if (typeof text === 'boolean') {
      return 'Follow-up';
    }
    return text && text.length > 20 ? text.substring(0, 17) + '...' : text;
  };

  const handleSelectRecentQuery = (recentQuery) => {
    setInputQuery(recentQuery.userQuery);
    setShowRecentSearches(false);
    setShowAutoComplete(false);
    setSuggestions([]);
    setHighlightedIndex(-1);
    onSubmit(recentQuery);
  };

  const handleFocus = async () => {
    setIsFocused(true);
    if (!isFollowUpMode) {
      setShowRecentSearches(true);
      refreshRecentSearches();
    }
  };

  const handleQuickFilterSubmit = (query, context) => {
    onSubmit(query, false, '', context);
    setShowQuickFilter(false);
    setInputQuery('');
  };

  const handleRemoveTable = () => {
    setSelectedTable(null);
    setSelectedColumn(null);
  };

  const handleMentionSelect = ({ type, item, mentionStartIndex, formattedValue }) => {
    const textBeforeMention = inputQuery.slice(0, mentionStartIndex);
    const textAfterMention = inputQuery.slice(cursorPosition);  // Changed from inputValue to inputQuery
  
    const mentionText = type === 'value'
      ? `@${item.column.name}:${item.value}`
      : `@${formattedValue}`;
  
    const spacer = textAfterMention.startsWith(' ') ? '' : ' ';
    const updatedInput = `${textBeforeMention}${mentionText}${spacer}${textAfterMention}`;
    
    const newMention = {
      type,
      value: formattedValue,
      start: mentionStartIndex,
      end: mentionStartIndex + mentionText.length,
      item,
      isFromValueSearch: type === 'value' && item.isFromValueSearch
    };
  
    setSelectedTable(type === 'table' ? item : selectedTable);
    setSelectedColumn(type === 'column' ? item : selectedColumn);
    setInputQuery(updatedInput);
    setShowMentionList(false);
  
    const newPosition = mentionStartIndex + mentionText.length + spacer.length;
    requestAnimationFrame(() => {
      if (textareaRef.current) {
        textareaRef.current.focus(); 
        textareaRef.current.setSelectionRange(newPosition, newPosition);
      }
      setCursorPosition(newPosition);
    });
  };

  return (
    <div className="relative w-full">
      {showQuickFilter ? (
        <QuickFilter 
          onSubmit={handleQuickFilterSubmit}
          onClose={() => setShowQuickFilter(false)}
        />
      ) : (
        <form onSubmit={handleSubmit} className="mb-4 relative w-full">
          <div className="relative w-full">
            {isFollowUpMode && selectedColumns.length > 0 && (
              <SelectedColumnsChip columns={selectedColumns} />
            )}
            <div className="relative w-full">
              {isFollowUpMode && (
                <>
                  <div 
                    ref={chipRef}
                    className={`selected-text-chip absolute left-2.5 top-3 text-xs px-2 py-1 rounded-full ${
                      isCardTitle 
                        ? 'bg-gray-200 text-gray-700' 
                        : 'bg-secondary text-white ring-1 ring-secondary'
                    } z-10 font-medium shadow-sm`}
                  >
                    {selectedText ? truncateSelectedText(selectedText) : truncateSelectedText(isCardTitle)}
                  </div>
                  {selectedColumns.length > 0 && (
                    <div 
                      ref={columnsChipRef}
                      className="columns-chip absolute left-2.5 top-9 text-xs px-2 py-1 rounded-full bg-primary text-white z-10 font-medium shadow-sm"
                    >
                      {selectedColumns.join(', ')}
                    </div>
                  )}
                </>
              )}
              <div className="w-full relative">
                <div 
                  className={`bg-gray-700 rounded-full focus-within:ring-2 focus-within:ring-secondary ${
                    isFollowUpMode ? 'pl-4' : 'pl-6'
                  } pr-12 sm:pr-16 overflow-hidden`}
                >
                  <textarea
                    ref={textareaRef}
                    value={inputQuery}
                    onChange={handleTextareaChange}
                    onKeyDown={handleKeyDown}
                    onFocus={handleFocus}
                    onBlur={() => setIsFocused(false)}
                    placeholder={currentPlaceholder}
                    className="w-full bg-gray-700 border-none outline-none resize-none text-white rounded-full block leading-6"
                    style={{
                      paddingLeft: isFollowUpMode 
                        ? `${Math.max(10 + (chipRef.current?.offsetWidth || 0) + 5, 16)}px` 
                        : '16px',
                      paddingTop: '12px',
                      paddingBottom: '12px',
                      paddingRight: '32px',
                      height: `${height}px`,
                      maxWidth: '100%',
                      minHeight: `${MIN_TEXTAREA_HEIGHT}px`,
                      overflow: height >= MIN_TEXTAREA_HEIGHT + (LINE_HEIGHT * (MAX_LINES - 1)) ? 'auto' : 'hidden'
                    }}
                    disabled={isLoading}
                  />
                </div>
                {!isFollowUpMode && (
                  <img
                    src="/favicon.ico"
                    alt="Company Icon"
                    className="absolute left-2.5 top-1/2 -translate-y-1/2 w-6 h-6"
                  />
                )}
                <div className="absolute right-2 top-1/2 -translate-y-1/2 flex items-center space-x-1 sm:space-x-2">
                  {inputQuery && (
                    <button
                      type="button"
                      onClick={clearInput}
                      className="p-1 sm:p-1.5 hover:bg-gray-600 rounded-full transition-colors duration-200"
                      disabled={isLoading}
                    >
                      <X className="w-4 h-4 text-gray-400" />
                    </button>
                  )}
                  {!isFollowUpMode && !inputQuery && (
                    <>
                      <button
                        type="button"
                        onClick={() => setShowQuickFilter(true)}
                        className="p-1.5 hover:bg-gray-600 rounded-full transition-colors duration-200"
                        disabled={isLoading}
                      >
                        <SlidersHorizontal className="w-4 h-4 text-secondary" />
                      </button>
                      <button
                        type="button"
                        onClick={handleHistoryBack}
                        className="hidden p-1.5 hover:bg-gray-600 rounded-full transition-colors duration-200"
                        disabled={isLoading}
                      >
                        <ArrowLeft className="w-4 h-4 text-gray-400" />
                      </button>
                    </>
                  )}
                  <button
                    type="submit"
                    className="p-1 sm:p-1.5 hover:bg-gray-600 rounded-full transition-colors duration-200"
                    disabled={isLoading || !inputQuery.trim()}
                  >
                    <SendHorizontal 
                      className={`w-4 h-4 ${
                        inputQuery.trim() ? 'text-secondary' : 'text-gray-400'
                      }`}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {!isFollowUpMode && (
            <div ref={recentSearchesRef}>
              <RecentSearches
                onSelectQuery={handleSelectRecentQuery}
                isVisible={showRecentSearches}
                setIsVisible={setShowRecentSearches}
                recentSearches={filteredRecentSearches}
                highlightedIndex={highlightedIndex}
                onKeyDown={handleKeyDown}
              />
            </div>
          )}
          {isLoading && (
            <div className="h-10 mt-4">
              <div className="flex justify-center items-center">
                <div className="bubbling-loader">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <span className="ml-2 text-[#4B5563] text-base">Processing query...</span>
              </div>
            </div>
          )}
          <MentionList
            isVisible={showMentionList}
            onSelect={handleMentionSelect}
            onClose={() => setShowMentionList(false)}
            inputValue={inputQuery}
            cursorPosition={cursorPosition}
            selectedTable={selectedTable}
            onRemoveTable={handleRemoveTable}
          />
          <AutoCompleteSearch
            onSelect={handleAutoComplete}
            inputValue={inputQuery}
            cursorPosition={cursorPosition}
            minChars={3}
            onVisibilityChange={setShowAutoComplete}
            onSuggestionsChange={setSuggestions}
            onSelectedIndexChange={setSelectedIndex}
            isLoading={isLoading}  
          />
        </form>
      )}
    </div>
  );
};

export default QueryInput;